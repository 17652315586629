define("ods-app/router", ["exports", "ods-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL
  });
  Router.map(function () {
    this.route('pais', function () {
      this.route('record', {
        path: '/:pais_id'
      });
      this.route('departamento', {
        path: '/:pais_id/departamento'
      }, function () {
        this.route('record', {
          path: '/:departamento_id'
        });
        this.route('municipio', {
          path: '/:departamento_id/municipio'
        }, function () {
          this.route('record', {
            path: '/:municipio_id'
          });
        });
      });
      this.route('tutorial');
    });
    this.route('proyectos-existentes', function () {});
    this.route('proyecto', function () {
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('servicio', function () {
          this.route('factorh-departamento');
          this.route('cancelar-proyecto');
          this.route('sirvows-centro-costo');
          this.route('tutorial');
        });
        this.route('cliente');
        this.route('calculo-planilla');
        this.route('empleado', function () {
          this.route('asignar-cargo');
          this.route('cargos', function () {
            this.route('agregar', {
              path: '/:cargo_id'
            });
            this.route('listado');
          });
          this.route('informacion', {
            path: '/:empleado_id'
          });
        });
        this.route('factura', function () {
          this.route('record', {
            path: '/:factura_id'
          });
        });
        this.route('documentos', function () {
          this.route('record', {
            path: '/:documento_id'
          });
        });
        this.route('requerimientos');
      });
    });
    this.route('requerimiento', function () {
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('informacion', function () {
          this.route('factorh-puesto');
          this.route('reasignar-proyecto');
          this.route('cancelar-requerimiento');
          this.route('link-portal-web');
          this.route('documentos', {
            path: '/documento/:documento_id'
          });
        }); // this.route('seguimiento');

        this.route('informacion-compensacion', function () {
          this.route('planilla-cargo', {
            path: '/planilla-cargo/:cargo_id'
          });
          this.route('documentos', {
            path: '/documento/:documento_id'
          });
        });
        this.route('ingreso', function () {
          this.route('asociar-proyecto');
          this.route('asociar-planilla', {
            path: '/asociar-planilla/:proyectocandidato_id'
          });
        });
        this.route('perfil', function () {
          this.route('competencia', {
            path: '/competencia/:competencia_id'
          });
        });
        this.route('prueba', function () {
          this.route('record', {
            path: '/:prueba_id'
          });
        });
        this.route('investigacion', function () {
          this.route('record', {
            path: '/:investigacion_id'
          });
        });
        this.route('terna', function () {
          this.route('record', {
            path: '/:terna_id'
          });
        });
        this.route('entrevista', function () {
          this.route('record', {
            path: '/:entrevista_id'
          });
        });
        this.route('aplicacion', function () {
          this.route('record', {
            path: '/:aplicacion_id'
          });
          this.route('asociar-aplicacion');
        });
        this.route('llamada', function () {
          this.route('record', {
            path: '/:llamada_id'
          });
        });
        this.route('presentacion');
        this.route('prueba-tecnica');
        this.route('bitacora', function () {
          this.route('record', {
            path: '/:bitacora_id'
          });
        });
      });
    });
    this.route('servicio', function () {
      this.route('record', {
        path: '/:id'
      });
      this.route('tutorial');
    });
    this.route('fuente-reclutamiento', function () {
      this.route('record', {
        path: '/:id'
      });
      this.route('tutorial');
    });
    this.route('planilla-cargo', function () {
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('factorh-tipo-descuento');
      });
    });
    this.route('razon-cancelacion', function () {
      this.route('record', {
        path: '/:id'
      });
      this.route('tutorial');
    });
    this.route('cliente', function () {
      this.route('index', {
        path: ''
      }, function () {
        this.route('resumen', {
          path: '/resumen/:id'
        });
        this.route('tutorial');
      });
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('datos-generales', function () {
          this.route('factorh-empresa');
        });
        this.route('contactos', function () {
          this.route('record', {
            path: '/:contacto_id'
          });
        });
        this.route('datos-facturacion', function () {
          this.route('record', {
            path: '/:datosfacturacion_id'
          });
        });
        this.route('referencias', function () {
          this.route('record', {
            path: '/:referencia_id'
          });
        });
        this.route('perfiles-puestos', function () {
          this.route('record', {
            path: '/:perfilespuestos_id'
          }, function () {
            this.route('descriptor');
            this.route('perfil');
            this.route('competencias', function () {
              this.route('record', {
                path: '/:competencia_id'
              });
            });
          });
        });
        this.route('rangos-salariales', function () {
          this.route('record', {
            path: '/:rangossalariales_id'
          });
        });
        this.route('contratos');
        this.route('proyectos');
        this.route('datos-contrato');
      });
    });
    this.route('aplicacion', function () {
      this.route('index', {
        path: ''
      }, function () {
        this.route('tutorial');
      });
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('informacion');
        this.route('entrevista', function () {
          this.route('record', {
            path: '/:entrevista_id'
          });
        });
        this.route('llamada', function () {
          this.route('record', {
            path: '/:llamada_id'
          });
        });
        this.route('historial', function () {
          this.route('record', {
            path: '/:historial_id'
          });
        });
      });
    });
    this.route('candidatos-existentes', function () {
      this.route('index', function () {
        this.route('tutorial');
      });
    });
    this.route('candidato', function () {
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('aplicacion');
        this.route('informacion', function () {
          this.route('asociar-proyecto');
        });
        this.route('planilla-cargo', function () {
          this.route('record', {
            path: '/:cargo_id'
          });
        });
        this.route('documentos', function () {
          this.route('record', {
            path: '/:documento_id'
          });
        });
        this.route('experiencia-laboral', function () {
          this.route('record', {
            path: '/:experiencialaboral_id'
          });
        });
        this.route('formacion-academica', function () {
          this.route('record', {
            path: '/:formacion_academica_id'
          });
        });
      });
    });
    this.route('facturas', function () {});
    this.route('modelo-configuracion', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('role', function () {
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('generales');
        this.route('permisos');
      });
    });
    this.route('permission', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('menu', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('usuario', function () {
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('generales');
        this.route('acceso');
      });
    });
    this.route('notification-config', function () {
      this.route('record', {
        path: '/:id'
      }, function () {
        this.route('plantilla');
        this.route('configuracion');
      });
    });
    this.route('config', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('tutorial', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('moneda', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('dashboard');
    this.route('reporte-plazas');
    this.route('reporte-candidatos');
    this.route('reporte-requerimientos');
    this.route('reporte-aplicaciones');
    this.route('reporte-proyectos');
    this.route('reporte-gestion-calidad');
    this.route('reporte-planilla');
    this.route('proyecto-historico');
    this.route('reporte-usuarios');
    this.route('reporte-perfiles-puesto');
    this.route('reporte-referidos');
    this.route('requerimiento-historico');
    this.route('login');
    this.route('logout');
    this.route('recodificar-cliente');
    this.route('reset-password');
    this.route('reasignar-proyecto');
    this.route('area', function () {
      this.route('record', {
        path: '/:area_id'
      });
      this.route('subarea', {
        path: '/:area_id/subarea'
      }, function () {
        this.route('record', {
          path: '/:subarea_id'
        });
      });
    });
    this.route('bitacora-tipo-evento', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('reporte-bitacora-eventos');
    this.route('requerimiento-tipo-estatus', function () {
      this.route('record', {
        path: '/:id'
      });
    });
    this.route('reporte-historial-aplicaciones');
  });
  var _default = Router;
  _exports.default = _default;
});